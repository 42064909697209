import React from "react";
import { graphql, PageProps } from "gatsby";

import { Hero, PostList } from "components/ACF";
import { Layout } from "components";
import { ProjectPageByCategoryQuery } from "codegen";

interface ProjectCategoryArchivePageContext {
  currentPageNumber: number;
  numberOfPages: number;
}

const ProjectCategoryArchiveTemplate = ({
  pageContext,
  data,
  location,
}: PageProps<ProjectPageByCategoryQuery, ProjectCategoryArchivePageContext>): JSX.Element => {
  const { currentPageNumber, numberOfPages } = pageContext;
  const { post, posts, categories } = data;
  const { seo, name } = post;
  const allCategories = categories.nodes.map((node) => node);

  return (
    <Layout seo={seo}>
      <div className="category-archive__wrapper wrapper">
        <Hero
          data={{
            content: `<h1>${name}</h1>`,
            compact: true,
            breadcrumbs: "below",
            image: post?.featuredImage?.image,
          }}
          location={seo.breadcrumbs}
        />
        <PostList
          postListItems={posts.nodes}
          postListCategories={allCategories}
          browserLocation={location}
          data={{
            currentPageNumber: currentPageNumber,
            numberOfPages: numberOfPages,
          }}
        />
      </div>
    </Layout>
  );
};

export default ProjectCategoryArchiveTemplate;

export const ProjectCategoryArchivePageQuery = graphql`
  query ProjectPageByCategory($id: String!, $offset: Int!, $postsPerPage: Int!) {
    post: wpProjectCategory(id: { eq: $id }) {
      id
      slug
      uri
      name
      ...SEOProjectCategoryQuery
      featuredImage {
        image {
          title
          sourceUrl
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 80, placeholder: BLURRED, width: 1800)
            }
          }
        }
      }
    }
    categories: allWpProjectCategory(filter: { count: { gt: 0 } }) {
      nodes {
        id
        name
        slug
        uri
      }
    }
    posts: allWpProject(
      sort: { fields: [date], order: DESC }
      filter: { projectCategories: { nodes: { elemMatch: { id: { eq: $id } } } } }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        ...ProjectFields
      }
    }
  }
`;
